@import "../StudentPay/StudentPay";
.alignright {
    text-align: right;
}

.role-table {
  margin: auto auto;
  text-align: left;
  width: 70%;
}

.role-table td {
    padding: 5px 0;
    border-top: #eee 1px solid;
  }

.student-application-status {
  .inner {
    display: flex;
  }

  &__img {
    svg {
      margin: 0 auto;
      display: block;
      width: 64px;
      height: 64px;
    }
  }

  &__info {
    margin-left: 30px;
    //flex: 0 1 450px;
  }

  &__text {
    //max-width: 400px;
  }

  &__buttons {
    //display: revert;
    width: 100%;
    justify-content: space-between;
    background-color: #6E41E2 !important;
    border-color: #6E41E2 !important;
    border-radius: 4px;
    text-align: center;
    &:hover, &:focus {
      background-color: #5635AB !important;
      border-color: #5635AB !important;
      color: $white;
  }
  }


  &__go-back {
    //display: revert;
    width: 20%;
    justify-content: space-between;
    background-color: #6E41E2 !important;
    border-color: #6E41E2 !important;
    border-radius: 4px;
    text-align: center;
    &:hover, &:focus {
      background-color: #5635AB !important;
      border-color: #5635AB !important;
      color: $white;
  }
  }

  &__pay-button {
    //display: revert;
    width: 100%;
    justify-content: space-between;
    background: $pink !important;
    border-color: #00000000 !important;
    border-radius: 4px;
    text-align: center;
      &:hover, &:focus {
        background-color: $dark-pink !important;
        border-color: $dark-pink !important;
        color: $white;
  }
  }

  svg {
    path, circle {
      fill: $white;
    }
  }

  @media (max-width: 400px) {
    .inner {
      flex-direction: column;
    }

    &__img {
      margin-top: 0;
    }

    &__info {
      margin-left: 0;
    }
  }

  &__buttons {
    justify-content: space-between;
  }

  &__pay-button {
    justify-content: space-between;
  }

  &__discount-table {
    margin: auto auto;
    text-align: left;
    width: 90%;
  }

  &__discount-table td {
    padding: 5px 0;
    border-top: #eee 1px solid;
  }

  &__discount-table.total td {
      border-top: 2px solid #333;
      border-bottom: 2px solid #333;
      font-weight: bold;
  }

  @media (max-width: 500px) {
    &__buttons {
      flex-direction: column;
      justify-content: unset;
      button:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 500px) {
    &__pay-button {
      flex-direction: column;
      justify-content: unset;
      button:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
